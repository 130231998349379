import * as yup from "yup";

export const schema = {
  name: {
    name: "name",
    id: "contact-name",
    type: "text",
    component: "InputText",
    label: "Name",
    placeholder: "Enter Name",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
  emailFrom: {
    name: "emailFrom",
    id: "contact-emailFrom",
    type: "email",
    component: "InputText",
    label: "Email",
    placeholder: "Enter Email",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().email().required(),
  },
  description: {
    name: "description",
    id: "desiredResolution",
    type: "text",
    component: "InputTextarea",
    label: "Please describe the bug*",
    placeholder: "Type explanation",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
};

const schemaShape = Object.keys(schema).reduce((result, key) => {
  result[key] = schema[key].validation;
  return result;
}, {});

export const resolver = yup.object().shape(schemaShape);

export default {
  schema,
  resolver,
};
