import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormSubmit from "components/forms/FormSubmit";
import InputText from "components/forms/InputText";
import InputTextarea from "components/forms/InputTextarea";
import FormSuccess from "components/forms/FormSuccess";
import FormError from "components/forms/FormError";
import FormLegals from "components/forms/FormLegals";

import { schema, resolver } from "data/form-schemas/consumer-complaints-bug.js";
import { sendContactForm } from "services/index.js";

// import "./ContactForm.scss";

export default function ConsumerComplaintsBug({
  emailTo = "btaylor@loanpal.com",
  emailToCopy = false,
}) {
  const [formStatus, setFormStatus] = useState("WAIT");
  const [submited, setSubmited] = useState(false);

  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(resolver),
  });

  const onSubmit = async ({ name, emailFrom, description }) => {
    setSubmited(true);

    const formData = {
      emailTo: emailTo,
      emailFrom: emailFrom,
      subject: `Loanpal bug found from ${name}`,
      name: name,
      bug: description,
    };

    try {
      const response = await sendContactForm(formData);
      if (!emailToCopy) {
        setFormStatus("SENT");
      }
    } catch (err) {
      setFormStatus("ERROR");
    }

    if (emailToCopy) {
      try {
        const response = await sendContactForm({
          ...formData,
          emailTo: emailToCopy,
        });
        setFormStatus("SENT");
      } catch (err) {
        setFormStatus("ERROR");
      }
    }

    setSubmited(false);
  };

  return (
    <div className="form-do-not-sell">
      {formStatus === "SENT" && <FormSuccess type="do-not-sell" />}

      {formStatus === "ERROR" && <FormError emailTo={emailTo} />}

      {formStatus === "WAIT" && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>Please complete the form below.</h2>
          <p className="disclaimer">
            Required fields <span>*</span>
          </p>

          <InputText
            {...schema["name"]}
            register={register}
            errors={errors["name"]}
            value={watch("name")}
          />

          <InputText
            {...schema["emailFrom"]}
            register={register}
            errors={errors["emailFrom"]}
            value={watch("emailFrom")}
          />

          <InputTextarea
            {...schema["description"]}
            register={register}
            errors={errors["description"]}
            value={watch("description")}
          />

          <FormLegals />

          <FormSubmit data-emailto={emailTo} disabled={submited} />
        </form>
      )}
    </div>
  );
}
